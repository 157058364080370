<template>
    <v-main>          
      <ReleaseList/>
    </v-main>
</template>
<script>
import ReleaseList from '../components/ReleaseList'
export default {
   components: {
    ReleaseList
  },
  data: () => ({   
  }),    
}
</script>
