<!-- ReleaseDialog.vue -->

<template>
  <v-dialog v-model="isVisible" max-width="800px" persistent>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Add Release</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-container>
          <form class="pt-3">
            <v-row>
              <v-col cols="3" v-for="field in ['appVersion', 'apiVersion']" :key="field">
                <v-text-field
                    :placeholder="'#.##'"
                    v-model="newRelease[field]"
                    dense
                    outlined
                >
                  <template v-slot:label>
                    <div>{{ field === 'appVersion' ? 'App Version' : 'Api Version' }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    type="date"
                    v-model="newRelease.releaseDate"
                    dense
                    outlined
                >
                  <template v-slot:label>
                    <div>Released</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="mb-2">
                  New Features or Bug fixes
                  <v-icon @click="addReleaseNote">mdi-plus</v-icon>
                </div>
                <v-text-field
                    v-for="(note, index) in newRelease.releaseNotes"
                    :key="index"
                    v-model="note.note"
                    dense
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="saveNewRelease">Save</v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    newRelease: Object,
  },
  methods: {
    addReleaseNote() {
      this.newRelease.releaseNotes.push({ note: "" });
    },
    saveNewRelease() {
      this.$emit("save");
    },
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>
