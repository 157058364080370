import * as BaseService from "./BaseService";
import * as master from "../types/master";

export default class ReleaseService extends BaseService.BaseService {
    private url = "/api/Release";

    constructor() {
        super();
    }

    async getReleases(): Promise<Array<master.Release>> {
        let response = (await this.api.get(this.url)).data as Array<master.Release>;
        return response;
    }

    async saveRelease(release: master.Release): Promise<void> {
        let response = await this.api.post(this.url, release);
    }
}