<template>
  <div>
    <v-card elevation="2" class="ma-2">
      <v-card-title>Releases</v-card-title>
      <v-row v-if="hasAdminAccess">
        <v-col class="text-right">
          <v-btn color="primary" class="mr-3" @click="toggleDialogVisibility">Add New Release</v-btn>
        </v-col>
      </v-row>
      <v-list subheader two-line>
        <v-list-item v-for="(release, index) in releaseList" :key="'release'+index">
          <release-list-item :release-data="release" />
          <v-divider v-if="index < releaseList.length - 1" :key="'divider'+index"></v-divider>
        </v-list-item>
      </v-list>
    </v-card>
    
    <release-dialog :is-visible="isDialogVisible" :new-release="newRelease" @save="saveNewRelease" @close="closeDialog" />
  </div>
</template>

<script>
import ReleaseService from "../services/ReleaseService";
import SecurityMixin from "@/mixins/security";
import ReleaseListItem from "./ReleaseListItem.vue";
import ReleaseDialog from "./ReleaseDialog.vue";

export default {
  mixins: [SecurityMixin],
  data() {
    return {
      isDialogVisible: false,
      isLoading: true,
      releaseList: [],
      newRelease: {
        appVersion: "",
        apiVersion: "",
        releaseDate: null,
        releaseNotes: [{ note: "" }],
      },
    };
  },
  created() {
    this.initializeData();
  },
  methods: {
    resetNewRelease() {
      this.newRelease = {
        appVersion: "",
        apiVersion: "",
        releaseDate: null,
        releaseNotes: [{ note: "" }],
      };
    },
    addReleaseNote() {
      this.newRelease.releaseNotes.push({ note: "" });
    },
    toggleDialogVisibility() {
      this.resetNewRelease();
      this.isDialogVisible = !this.isDialogVisible;
    },
    initializeData() {
      this.isLoading = true;
      new ReleaseService()
          .getReleases()
          .then((data) => {
            this.releaseList = data;
          })
          .finally(() => (this.isLoading = false));
    },
    saveNewRelease() {
      this.isLoading = true;
      new ReleaseService().saveRelease(this.newRelease).then(() => {
        this.initializeData();
        this.toggleDialogVisibility();
      });
    },
    closeDialog() {
      this.isDialogVisible = false;
    },
  },
  components: {
    ReleaseListItem,
    ReleaseDialog, 
  },
  computed: {
    hasAdminAccess() {
      return this.hasAccess('AdminMenu');
    },
  },
};
</script>

