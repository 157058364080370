<template>
  <v-list-item :key="releaseData.releaseId">
    <v-list-item-avatar>
      <v-icon class="grey lighten-1" dark color="blue">mdi-package-variant</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>Achilles Version {{ releaseData.appVersion }}</v-list-item-title>
      <v-list-item-subtitle>Released: {{ formatDate(releaseData.releaseDate) }}</v-list-item-subtitle>
      <div v-for="(note, index) in releaseData.releaseNotes" :key="'note'+index">
        <v-list-item-subtitle
            v-if="note.hasHtml"
            v-html="note.note"
        ></v-list-item-subtitle>
        <v-list-item-subtitle v-else><li>{{ note.note }}</li></v-list-item-subtitle>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    releaseData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
  },
};
</script>

<style scoped>

</style>
